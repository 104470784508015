<template>
  <div class="pad-bottom wh-item">
    <RouterLink :to="routeTo">
      <div class="data-item__img" :style="`background-image:url(${mediaUrl})`" />
      <div v-if="item.title" class="category-head__title wh-item__title">{{ item.title }}</div>

      <template v-if="item.__typename === 'ArtFairType'">
        <div v-if="item.destination" class="d-flex wh-item__subcontent">
          <div v-if="item.destination.title" class="small-grey">{{ item.destination.title }}</div>
          <div v-if="item.destination.country" class="small-grey">, {{ item.destination.country }}</div>
        </div>
        <DataItemDates v-if="isShowDates" class="wh-item__subcontent" :dates="item.date" />
      </template>

      <div v-else-if="item.__typename === 'EventType'" class="d-flex wh-item__subcontent">
        <div v-if="item.destination && item.destination.title" class="small-grey">
          {{ item.destination.title }}
        </div>
        <div v-if="startDate" class="small-grey ml-1">| {{ startDate }}</div>
      </div>

      <div v-if="item.type" class="small-grey">{{ item.type }}</div>
      <template v-else-if="item.__typename === 'ExhibitionType'">
        <div class="d-flex wh-item__subcontent">
          <div v-if="item.destination && item.destination.title" class="small-grey">{{ item.destination.title }}</div>
          <div v-if="item.artSpace && item.artSpace.title" class="small-grey ml-1">| {{ item.artSpace.title }}</div>
        </div>
        <DataItemDates v-if="isShowDates" class="wh-item__subcontent" :dates="item.date" />
      </template>
    </RouterLink>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { formatDate } from '@/helpers/dateTimeHelper';
import DataItemDates from '@/components/partials/DataItemDates';

export default {
  name: 'DestinationWhatsHappeningItem',
  components: { DataItemDates },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isShowDates() {
      return this.item.date && this.item.date.length;
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
    routeTo() {
      return getEntityRoute(this.item);
    },
    startDate() {
      return formatDate(this.item.earliest_start_date);
    },
  },
};
</script>

<style lang="scss">
.wh-item {
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-bottom: 50px;
  }

  .data-item__img {
    transition: all 0.2s linear;
  }

  a:hover {
    .data-item__img {
      transform: scale(0.95);
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.85px;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__subcontent {
    .small-grey {
      font-size: 14px;
      line-height: 20px;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}
</style>
