<template>
  <div>
    <div v-if="isLoading" class="loader-wrapper">
      <AppLoader />
    </div>
    <div v-else-if="isNoResults" class="without-result">
      <p class="without-result__title">No results found</p>
      <p class="without-result__text">
        If there's something you want to see or do and you can't find it in our app, just contact us and we'll do our
        best to make it happen for you.
      </p>
      <button class="btn border-btn without-result__btn" @click="$router.push({ name: 'contact-us' })">
        Make a request
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DestinationTabNoResults',
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    isNoResults: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  display: flex;
  justify-content: center;
}
</style>
